/* Older browsers (do now support CSS Environment variables). */
.SignInFormContainer {
  padding-top: 20px;
}

/* Browsers which partially support CSS Environment variables (iOS 11.0-11.2). */
@supports (padding-top: constant(safe-area-inset-top)) {
  .SignInFormContainer {
    --safe-area-inset-top: constant(safe-area-inset-top);

    padding-top: calc(20px + var(--safe-area-inset-top));
  }
}
/* Browsers which fully support CSS Environment variables (iOS 11.2+). */
@supports (padding-top: env(safe-area-inset-top)) {
  .SignInFormContainer {
    --safe-area-inset-top: env(safe-area-inset-top);

    padding-top: calc(20px + var(--safe-area-inset-top));
  }
}
