/* Dashed border */
hr.dashed {
    border-top: 1px dashed #bbb;
}

/* Dotted border */
hr.dotted {
    border-top: 1px dotted #bbb;
}

/* Solid border */
hr.solid {
    border-top: 1px solid #bbb;
}

/* Rounded border */
hr.rounded {
    border-top: 5px solid #bbb;
    border-radius: 5px;
}

.custom-section-title {
    margin-left: 10px;
}

.noborder-list-item {
    --inner-border-width: 0;
}

.bordered-text-block {
    border: solid 1px #CCCCCC;
    margin: 0 10px;
    padding: 10px;
    min-height: 6em;
}

.bordered-input-textarea {
    border: solid 1px #DDD;
    margin: 10px;
    min-height: 6em;
}

.bordered-input-textarea ion-textarea {
    margin: 0;
}

textarea[disabled] {
    opacity: 1 !important;
}

.item-interactive-disabled ion-label {
    opacity: 1 !important;
}

ion-footer ion-button {
    margin: 0;
}

/* https://fkhadra.github.io/react-toastify/how-to-style */
.Toastify__toast-container {
  z-index: 100000;
  padding: 1rem !important;
  margin-top: env(safe-area-inset-top, 1rem) !important;
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
  border-radius: .5rem !important;
}

.Toastify__toast {
  border-radius: .5rem !important;
}
.Toastify__toast--error {
  background: linear-gradient(0.25turn, #f50e0b, #a2000a);
}

.Toastify__progress-bar {
  background: linear-gradient(0.25turn, #f50e0b, #a2000a);
}
.Toastify__progress-bar--error {
  background: linear-gradient(0.25turn, #bbbbbb, #666666);
}
